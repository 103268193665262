<template>
  <div class="list-area">
    <el-row>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="handleOperate('create')"
        >导入榜单</el-button
      >
    </el-row>

    <PageTable
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
    >
      <template slot-scope="{ row, index }" slot="id">
        {{ index + 1 }}
      </template>

      <template slot-scope="{ row }" slot="appCategoryId">
        {{ row.appCategoryId | typeDesc(appTypeList) }}榜
      </template>

      <template slot-scope="{ row }" slot="fileName">
        <div v-for="(item, index) in row.fileName.split(',')" :key="index">
          <a :href="row.filePath.split(',')[index]">{{ item }}</a>
        </div>
      </template>
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <el-popconfirm title="确定删除吗？" @confirm="handleDelete(row)">
            <el-button slot="reference" class="ml10" type="text">
              <span class="colorRed">删除</span>
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </PageTable>

    <el-dialog
      top="10vh"
      destroy-on-close
      :close-on-click-modal="false"
      title="导入榜单"
      :visible.sync="visible"
      @closed="$refs.createRef && $refs.createRef.resetFields()"
    >
      <Create
        ref="createRef"
        v-if="operationType === 'create'"
        @submit="handleImport"
        @cancle="visible = false"
      />
    </el-dialog>
  </div>
</template>
<script>
import { appTypeList } from "@/common/constant/constant.js";
import PageTable from "@/components/PageTable.vue";
import Create from "./Create.vue";
import { getCategoryList, batchImport, delImportData } from "./indexServe";
export default {
  name: "resImport",
  components: { PageTable, Create },
  mounted() {
    this.init();
  },
  data() {
    return {
      appTypeList,
      languageList: [],
      firstClassList: [],
      secondClassList: [],
      thirdClassList: [],
      searchData: {
        firstClassId: "",
        secondClassId: "",
        thirdClassId: "",
      },
      tableData: {
        list: [],
      },
      columns: [
        {
          slot: "id",
          label: "序号",
          align: "center",
        },
        {
          key: "countryName",
          label: "国家",
          align: "center",
        },
        {
          slot: "appCategoryId",
          label: "榜单",
          align: "center",
        },
        {
          slot: "fileName",
          label: "文件名称",
          align: "center",
        },
        {
          key: "createTime",
          label: "导入时间",
          align: "center",
        },
        {
          key: "exportUserName",
          label: "导入人员",
          align: "center",
        },
      ],
      visible: false, //控制drawer的显示和隐藏
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      detailData: {},
      selectionIdList: [], //所有已选的id列表
    };
  },
  methods: {
    handleDelete(row) {
      delImportData({ id: row.id }).then((res) => {
        if (res && res.code == 0) {
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 删除成功后刷新列表
          this.$message.success("删除成功");
        }
      });
    },
    // 事件监听
    handleChangePage({ page, size }) {
      this.tableData.page = page;
      this.tableData.size = size;
      this.getTableList({ page, size });
    },
    handleOperate(operationType, row) {
      this.operationType = operationType;
      this.visible = true;
      if (operationType === "create") {
        this.detailData = {};
      }
    },
    handleImport(params) {
      const formData = new FormData();
      formData.set("countryName", params.countryName);
      formData.set("appCategoryId", params.appCategoryId);
      params.files.forEach((file) => {
        formData.append("files", file);
      });

      batchImport(formData).then((res) => {
        if (res && res.code === 0) {
          this.visible = false;
          this.detailData = {};
          this.getTableList(); // 成功后刷新列表
        }
      });
    },

    // 后端请求
    // 获取列表
    async getTableList(params, isDownload) {
      const { secondClassId, firstClassId, thirdClassId } = this.searchData;
      const parentId = thirdClassId || secondClassId || firstClassId; //将当前id填入parentCateId
      const res = await getCategoryList({
        page: 1,
        size: 10,
        parentId,
        ...params,
      });
      if (res && res.code === 0) {
        // 下载时，不需要更新到table
        if (!isDownload) {
          this.tableData = res.data;
        }
        return res.data;
      }
    },

    // 其他
    init() {
      this.getTableList();
    },
  },
};
</script>
<style scoped lang="scss">
.list-area {
  position: relative;
  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;
    div {
      margin-right: 5px;
    }
  }
}
</style>
